import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { noop } from 'lodash';
import { isDemoEnv } from '../utils';

export interface DatadogArgs {
  environment: string;
  version: string;
  applicationId: string;
  clientToken: string;
  service: string;
  proxy?: string;
}

// Non-prod back-ends go down a lot, so this allows us to ignore some errors
const IGNORED_UAT_ERRORS: Set<string> = new Set([
  'Failed to fetch',
  'NetworkError when attempting to fetch resource.',
  "csp_violation: 'eval' blocked by 'script-src' directive",
]);
const IGNORED_PROD_ERRORS: Set<string> = new Set([
  // AG-grid attempts to run `eval` for features we don't use, see https://www.ag-grid.com/javascript-data-grid/security/#script-src
  "csp_violation: 'eval' blocked by 'script-src' directive",
]);

export function initDatadog({ environment, version, applicationId, clientToken, service, proxy }: DatadogArgs) {
  const isLocal = environment === 'local';

  const commonConfiguration = {
    applicationId,
    clientToken,
    service,
    proxy,
    env: environment,
    version,
    useSecureSessionCookie: !isLocal,
  } as const;

  // Real-user monitoring (session replay, long tasks, etc.)
  datadogRum.init({
    ...commonConfiguration,
    trackUserInteractions: true,
    trackLongTasks: true,
    trackResources: !isLocal,
    telemetrySampleRate: 0,
    sessionReplaySampleRate: isDemoEnv ? 0 : 20,
    beforeSend: event => {
      if (isLocal) {
        let consoleMethod: (message?: any, ...optionalParams: any[]) => void;
        switch (event.type) {
          case 'long_task':
          case 'vital':
            // Ignore logging long_task & vital events. They're not useful.
            consoleMethod = noop;
            break;
          case 'error':
            consoleMethod = console.warn;
            break;
          default:
            // eslint-disable-next-line no-console
            consoleMethod = console.debug;
            break;
        }
        consoleMethod('[Datadog]', event.type, event);
        // Datadog whines if we don't return true for views
        if (event.type === 'view') {
          return true;
        }
        return false;
      }

      // If the page was discarded, we want to know about it
      // See https://developer.chrome.com/blog/memory-and-energy-saver-mode
      if (event.type === 'long_task' && 'wasDiscarded' in document && document.wasDiscarded) {
        event.context = { ...event.context, wasDiscarded: true };
      }

      // Ignore certain errors
      const isProduction = environment === 'prod' || environment === 'sandbox';
      const ignoredErrors = isProduction ? IGNORED_PROD_ERRORS : IGNORED_UAT_ERRORS;
      if (event.error instanceof Error && ignoredErrors.has(event.error.message)) {
        return false;
      }

      return true;
    },
  });

  // Logs
  datadogLogs.init({
    ...commonConfiguration,
    forwardErrorsToLogs: !isLocal,

    // note that debug/info are manually forwarded in our logger
    forwardConsoleLogs: isLocal ? [] : ['error', 'warn', 'info'],
  });
}
