import { createContext, memo, useContext, useEffect, useState, type ReactNode } from 'react';
import { useAuthContext, useEndpointsContext } from '../contexts';
import { WebSocketStatus } from '../tokens/socket';
import { logger } from '../utils';
import { WebSocketClient, WebSocketPerformance, type IWebSocketClient } from './WebSocketClient';

export const CLOSE_NORMAL = 1000;
export const CLOSE_GOING_AWAY = 1001;
export const CLOSE_ABNORMAL = 1006;
export const WEBSOCKET_CLOSE_CODES = {
  [CLOSE_NORMAL]: 'Normal Closure',
  [CLOSE_GOING_AWAY]: 'Going Away',
  1002: 'Protocol Error',
  1003: 'Unsupported Data',
  1004: '(For future)',
  1005: 'No Status Received',
  [CLOSE_ABNORMAL]: 'Abnormal Closure',
  1007: 'Invalid frame payload data',
  1008: 'Policy Violation',
  1009: 'Message too big',
  1010: 'Missing Extension',
  1011: 'Internal Error',
  1012: 'Service Restart',
  1013: 'Try Again Later',
  1014: 'Bad Gateway',
  1015: 'TLS Handshake',
};

export function getCloseCodeString(code) {
  if (code >= 0 && code <= 999) {
    return '(Unused)';
  }
  if (code <= 1015) {
    return WEBSOCKET_CLOSE_CODES[code];
  }
  if (code <= 1999) {
    return '(For WebSocket standard)';
  }
  if (code <= 2999) {
    return '(For WebSocket extensions)';
  }
  if (code <= 3999) {
    return '(For libraries and frameworks)';
  }
  if (code <= 4999) {
    return '(For applications)';
  }
}

export interface WebSocketContextProps<TMessageType> {
  client?: IWebSocketClient<TMessageType> | null;
  status: WebSocketStatus;
  performance?: any;
}
export const WebSocketContext = createContext<WebSocketContextProps<unknown>>({
  status: WebSocketStatus.CONNECTING,
});
WebSocketContext.displayName = 'WebSocketContext';

export function useSocketClient<TMessageType = unknown>() {
  const { client } = useContext(WebSocketContext);
  if (client === undefined) {
    throw new Error('Missing WebSocketClient.Provider further up in the tree. Did you forget to add it?');
  }
  return client as IWebSocketClient<TMessageType>;
}

export function useSocketStatus() {
  const { status } = useContext(WebSocketContext);
  return status;
}

type WebSocketClientProviderProps = {
  wsEndpoint?: string;
  pingPongTypes?: WebSocketClient['pingPongTypes'];
  children: ReactNode;
  wsClient?: WebSocketClient;
};

export const WebSocketClientProvider = memo(function WebSocketClientProvider(props: WebSocketClientProviderProps) {
  const authContext = useAuthContext();
  const { wsEndpoint } = useEndpointsContext();
  const [client, setClient] = useState<WebSocketClient | null>(null);
  const [status, setStatus] = useState(WebSocketStatus.CONNECTING);
  const [socketUrl, setSocketUrl] = useState<string>();
  const performance = useState<WebSocketPerformance | null>(null);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      const urlWithVersion = `${wsEndpoint}/${import.meta.env.VITE_GIT_HASH}`;
      setSocketUrl(urlWithVersion);
    }
  }, [authContext, wsEndpoint]);

  const isAuthenticated = authContext?.isAuthenticated;
  useEffect(() => {
    if (socketUrl && isAuthenticated) {
      const performance = new WebSocketPerformance();
      const client =
        props.wsClient ??
        new WebSocketClient({
          pingPongTypes: props.pingPongTypes,
        });
      client.performance = performance;
      const onOpen = client.onOpen.subscribe(() => {
        setStatus(WebSocketStatus.CONNECTED);
      });
      const onClose = client.onClose.subscribe(e => {
        setStatus(WebSocketStatus.RECONNECTING);
        const nav: any = navigator;

        // Ignore expected codes (normal closure, going away, abnormal closure)
        if (![CLOSE_NORMAL, CLOSE_GOING_AWAY, CLOSE_ABNORMAL].includes(e.code)) {
          logger.info(`Websocket closed abnormally`, {
            websocket: {
              code: e.code,
              message: getCloseCodeString(e.code),
              reason: e.reason,
              wasClean: e.wasClean,
              session_id: client?.sessionId,
            },
            connection:
              'connection' in nav
                ? {
                    connectionType: nav.connection.type,
                    rtt: nav.connection.rtt,
                    effectiveType: nav.connection.effectiveType,
                    downlink: nav.connection.downlink,
                  }
                : {},
          });
        }
      });
      client.connect(socketUrl);
      performance.start();
      setClient(client);
      return () => {
        performance.stop();
        client.close();
        onOpen.unsubscribe();
        onClose.unsubscribe();
        setClient(null);
      };
    } else {
      setClient(null);
    }
  }, [isAuthenticated, socketUrl, props.pingPongTypes, props.wsClient]);

  return (
    <WebSocketContext.Provider value={{ client, status, performance }}>{props.children}</WebSocketContext.Provider>
  );
});
