import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { createContext, useCallback, useContext, useEffect } from 'react';
import { map } from 'rxjs/operators';
import {
  useEndpointsContext,
  useUserContext as useKyokoUserContext,
  useMixpanel,
  UserContext,
  type UserContextProps,
} from '../contexts';
import { useObservableValue } from '../hooks/useObservable';
import { useStaticSubscription } from '../hooks/useStaticSubscription';
import type { CUSTOMER_USER_API_KEY } from '../tokens';
import type { CustomerUser, CustomerUserApiKey } from '../types';
import { DELETE, GET, PATCH, POST, request } from '../utils';

export interface WLUserApiKeyContextProps {
  getUserApiKeys: (userID: string) => Promise<CustomerUserAPIKeyResponse>;
  createUserApiKey: (userID: string) => Promise<CustomerUserAPIKeyResponse>;
  deleteUserApiKey: (userID: string, apiKeyID: string) => Promise<CustomerUserAPIKeyResponse>;
  updateUserApiKey: (apiKey: CustomerUserApiKey) => Promise<CustomerUserAPIKeyResponse>;
}

type CombinedUserContextProps = UserContextProps & WLUserApiKeyContextProps;

export const WLUserApiKeyContext = createContext<WLUserApiKeyContextProps | undefined>(undefined);
WLUserApiKeyContext.displayName = 'WLUserApiKeyContext';

export function useWLUserContext(): CombinedUserContextProps {
  const userContext = useKyokoUserContext();
  const userApiKeyContext = useContext(WLUserApiKeyContext);
  if (userApiKeyContext === undefined) {
    throw new Error('Missing WLUserApiKeyContext.Provider further up in the tree. Did you forget to add it?');
  }
  return {
    ...userContext,
    ...userApiKeyContext,
  };
}

export function WLUserApiKeyContextProvider({ children }) {
  const { apiEndpoint } = useEndpointsContext();
  const userEndpoint = `${apiEndpoint}/user`;

  const getUserApiKeys = useCallback((userID: string) => request(GET, `${userEndpoint}/api-key`, null), [userEndpoint]);
  const createUserApiKey = useCallback(
    (userID: string) => request(POST, `${userEndpoint}/api-key`, null),
    [userEndpoint]
  );
  const deleteUserApiKey = useCallback(
    (userID: string, apiKeyID: string) => request(DELETE, `${userEndpoint}/api-key/${apiKeyID}`, null),
    [userEndpoint]
  );
  const updateUserApiKey = useCallback(
    (apiKey: CustomerUserApiKey) => request(PATCH, `${userEndpoint}/api-key`, apiKey),
    [userEndpoint]
  );

  return (
    <WLUserApiKeyContext.Provider
      value={{
        createUserApiKey,
        deleteUserApiKey,
        getUserApiKeys,
        updateUserApiKey,
      }}
    >
      {children}
    </WLUserApiKeyContext.Provider>
  );
}

export function WLUserContextProvider({ children }) {
  const { data: subscription } = useStaticSubscription({
    tag: 'WLUserContextProvider',
    name: 'User',
  });

  const mixpanel = useMixpanel();
  const user = useObservableValue<CustomerUser>(
    () => subscription.pipe(map(response => response?.data[0])),
    [subscription]
  );
  useEffect(() => {
    if (user?.CustomerUserID != null) {
      mixpanel.identify(user.CustomerUserID);
      datadogRum.setUser({
        id: user.CustomerUserID,
        name: user.DisplayName,
        email: user.Email,
      });
      datadogLogs.setUser({
        id: user.CustomerUserID,
        name: user.DisplayName,
        email: user.Email,
      });
    }
  }, [user, mixpanel]);

  const isLoaded = user != null;

  return (
    <UserContext.Provider
      value={{
        isLoaded,
        // @ts-expect-error UserContext interface in kyoko needs to be updated
        user,
      }}
    >
      <WLUserApiKeyContextProvider>{children}</WLUserApiKeyContextProvider>
    </UserContext.Provider>
  );
}

export function useWLUser(): CustomerUser {
  const { user } = useWLUserContext();
  return user as unknown as CustomerUser;
}

export interface CustomerUserAPIKeyResponse {
  type: typeof CUSTOMER_USER_API_KEY;
  initial: boolean;
  ts: string;
  data: CustomerUserApiKey[];
}
