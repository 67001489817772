import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export interface LogContext {
  extra?: Record<string, any>;
  [key: string]: any;
}

function error(error: Error, context?: LogContext): void {
  if (import.meta.env.DEV) {
    console.error(error);
    return;
  }
  datadogRum.addError(error, context);
  datadogLogs.logger.error(error.message, context);
}

function warn(message: string, context?: LogContext): void {
  if (import.meta.env.DEV) {
    console.warn(message);
    return;
  }
  datadogLogs.logger.warn(message, context);
}

function info(message: string, context?: LogContext): void {
  if (import.meta.env.DEV) {
    console.info(message);
    return;
  }
  datadogLogs.logger.info(message, context);
}

function debug(message: string, context?: LogContext): void {
  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.debug(message);
    return;
  }
  datadogLogs.logger.debug(message, context);
}

export const logger = {
  error,
  warn,
  info,
  debug,
};
